.homepage {
    display: flex;
    flex-direction: column; /* Organize children vertically */
    justify-content: flex-start; /* Start from the top */
    align-items: center; /* Center items horizontally */
    height: 100vh;
    text-align: center;
    cursor: none;
}

.hook {
  font-size: 42px;
  position: relative;
  background: rgba(255, 255, 255, 0.2); /* Semi-transparent white background */
  padding-left: 30px; /* Adjust padding as needed */
  padding-right: 30px; /* Adjust padding as needed */
  margin: 20px 0; /* Space around the text, adjust as needed */
  backdrop-filter: blur(10px); /* Frosted glass effect */
  -webkit-backdrop-filter: blur(10px); /* For Safari */
  border-radius: 100px;
  box-shadow: 0 8px 20px -5px rgba(0, 0, 0, 0.5); 
  cursor: auto;
}

.adjective-container {
  overflow: hidden;
  display: inline-flex;
  flex-direction: column;
  position: relative;
  line-height: 2;
  height: 2em;
  justify-content: flex-start;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}

.adjectives {
  display: flex;
  flex-direction: column;
  animation: scrollUp infinite 40s linear;
}

.adjectives span {
  white-space: nowrap;
  font-size: 42px;
}

@keyframes scrollUp {
  0% {
    transform: translateY(2%);
  }
  100% {
    transform: translateY(-98%);
  }
}

.hook::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
}

.hook::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
}

.hook::before {
  top: 0;
  height: 50%;
  background: linear-gradient(to bottom, rgba(255,255,255), rgba(255,255,255,0));
}

.hook::after {
  bottom: 0;
  height: 50%;
  background: linear-gradient(to top, rgba(255,255,255), rgba(255,255,255,0));
}

@media (max-width: 630px) {
  .hook {
    font-size: 26px; /* Smaller font size for phones */
    padding-left: 15px; /* Adjust padding as needed */
    padding-right: 15px; /* Adjust padding as needed */
  }

  .adjective-container {
    overflow: hidden;
    display: inline-flex;
    flex-direction: column;
    position: relative;
    line-height: 2;
    height: 2em;
    justify-content: flex-start;
    align-items: center;
    padding-left: 4px;
    padding-right: 4px;
  }

  .adjectives span {
    font-size: 26px; /* Ensure consistent font size within .hook */
  }
}


