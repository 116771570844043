@font-face {
    font-family: 'AltasynCustom';
    src: url('../assets/fonts/MontserratAlternates-LightItalic.ttf') format('truetype');
  }
  
  .body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantaloupe', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
  }

html::-webkit-scrollbar,
body::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* Optional: Just to ensure scrollbar is completely invisible */
}

/* Hide scrollbar for IE, Edge, and Firefox */
html,
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow-y: scroll; /* Still allows vertical scrolling */
}

/* For Chrome, Safari, and Opera, ensure overflow is hidden */
html,
body {
  overflow: -moz-scrollbars-none; /* Old Firefox */
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {  
  /* IE10+ specific styles */
  html,
  body {
    overflow: auto;
  }
}

