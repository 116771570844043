.topBar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(0, 0, 0);
    color: white;
    z-index: 1000;
    box-shadow: 0 8px 20px -5px rgba(0, 0, 0, 0.7); 
    cursor: auto;
  }

  .nameContainer, .linksContainer {
    padding: 10px;
  }
  
  .nameContainer {
    font-family: 'AltasynCustom';
    font-size: 35px;
    margin-left: 2%;
    font-weight: bold;
    cursor: default;
  }
  
  .linksContainer {
    margin-right: 2%;
  }
  
  .topBar a {
    margin: 0 15px;
    text-decoration: none;
    color: white;
    position: relative;
    display: inline-block;
  }
  
  .topBar a::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -5px;
    left: 0;
    background-color: white;
    visibility: hidden; /* Hide by default */
    transform: scaleX(0);
    transition: all 0.3s ease-in-out 0s; /* Smooth transition for the fade-in effect */
  }
  
  .topBar a:hover::after {
    visibility: visible;
    transform: scaleX(1);
  }
  
  @media (max-width: 630px) {
    .topBar a {
      font-size: 10px; /* Ensure consistent font size within .hook */
    }
  }